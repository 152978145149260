export interface AppInfo {
  //readonly commitHash: string;
  //readonly branch: string;
  readonly pipelineId: string;
  readonly clientVersion: string;
  readonly clientEnvironment: string;
  readonly markerName: string;
  readonly serverUrl: string;
}

export const appInfo: AppInfo = {
  //branch: "{branch}",
  pipelineId: "124883",
  //commitHash: "{commit_hash}",
  clientVersion: "2024-09-16.13:17",
  clientEnvironment: "use_host",
  serverUrl: "{server_url}",
  markerName: "{marker_name}",
};
